.Content-T {
  width: 100%;
  align-items: center;
  justify-content: center;
}

.TitleModules {
  margin-bottom: 100px;
  font-size: 50px;
  text-align: center;
  color: #1976d2;
}

.OLTManager {
  top: 0vw;
  right: 0px;
  width: 60vw;
}
.Finanzas {
  top: 0vw;
  right: 0px;
  width: 58vw;
}

.TYC {
  top: 0vw;
  right: 0px;
  width: 56vw;
}

.Instalaciones {
  top: 0vw;
  right: 0px;
  width: 50vw;
  margin-left: 10px;
}

.titleModulo {
  font-size: 50px;
  font-weight: 700;
  color: #1976d2;
}

.Caracteristicas {
  font-size: 18px;
  color: #42a5f5;
}

.OLTManager,
.Finanzas,
.TYC,
.Instalaciones {
  transition: transform 0.3s ease;
}

.elementCarousel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1200px) {
  .TitleModules {
    font-size: 40px;
  }
  .OLTManager,
  .Finanzas,
  .TYC,
  .Instalaciones {
    width: 80vw;
  }
  .titleModulo {
    font-size: 40px;
  }
  .Caracteristicas {
    font-size: 16px;
  }
  .elementCarousel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 800px) {
  .TitleModules {
    font-size: 30px;
    margin-bottom: 50px;
  }
  .OLTManager,
  .Finanzas,
  .TYC,
  .Instalaciones {
    width: 90vw;
    margin-left: 5px;
  }
  .titleModulo {
    font-size: 30px;
  }
  .Caracteristicas {
    font-size: 14px;
  }
  .elementCarousel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
