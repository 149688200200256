.VideoContainer {
  background-color: #42a5f5;
  height: 75vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  padding-left: 10vw;
  padding-right: 10vw;
  margin-bottom: 10vw;
}
.miVideo {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

#videoDimensions {
  border-radius: 8px;
  object-fit: cover;
}

.miTexto {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.titleText {
  font-size: 4vw;
  line-height: 1.2;
  color: #ffff;
}
.subtitulo {
  font-size: 28px;
  font-weight: 600;
  margin-top: -30px;
  color: #ffff;
}

@media (max-width: 1200px) {
  .VideoContainer {
    flex-direction: column;
    padding-left: 6vw;
    padding-right: 6vw;
    padding-bottom: 6vh;
    height: auto;
  }
  .miVideo {
    width: 95%;
    height: 50%;
  }
  .miTexto {
    width: 100%;
    text-align: center;
  }
  .titleText {
    font-size: 5vw;
  }
  .subtitulo {
    font-size: 3vw;
    margin-top: 0px;
  }
}

@media (max-width: 600px) {
  .VideoContainer {
    padding-left: 2vw;
    padding-right: 2vw;
    margin-bottom: 4vw;
  }
  .miVideo {
    width: 95%;
    height: auto;
  }
  .miTexto {
    width: 100%;
    text-align: center;
    padding-top: 2vw;
  }
  .titleText {
    font-size: 7vw;
  }
  .subtitulo {
    font-size: 5vw;
    margin-top: 0px;
    font-weight: 400;
  }
}
