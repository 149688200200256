.containerMissionVision {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
  margin-bottom: 7vw;
}
.contenedorTexto2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1.5;
}

.textTitle2 {
  font-size: 50px;
  color: #42a5f5;
}

.contenedorMV2 {
  flex: 1;
}

.contenedorMV2 {
  position: relative;
  width: 300px;
  height: 800px;
  /* Oculta las barras de desplazamiento */
}

.contenedorMV2 img {
  position: absolute;
}
.contenedorImagenes {
  position: relative;
  width: 300px;
  height: 600px;
  /* Oculta las barras de desplazamiento */
}

.contenedorMV img {
  position: absolute;
}

.illustrationMV2 {
  top: 0vw;
  right: 0px;
  width: 560px;
  box-shadow: 0 0 10px rgba(128, 128, 128, 0.5);
  border-radius: 10px;
}

.illustrationMV3 {
  width: 300px;
  top: 190px;
  left: 90px;
  box-shadow: 0 0 10px rgba(128, 128, 128, 0.5);
  border-radius: 10px;
}

.illustrationMV4 {
  width: 450px;
  bottom: 0px;
  right: 0px;
  box-shadow: 0 0 10px rgba(128, 128, 128, 0.5);
  border-radius: 10px;
}

.MissionIcon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.iconInstagrams {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #90caf9;
  font-size: 5vw;
  transition: transform 0.3s ease-in-out;
}

.iconInstagrams:hover {
  transform: scale(1.3);
}

.iconInstagrams2 {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1976d2;
  font-size: 5vw;
  transition: color 0.5s ease-in-out;
}

.iconInstagrams2:hover {
  color: #ffff;
}

@media (max-width: 1200px) {
  .containerMissionVision {
    flex-direction: column;
  }

  .textTitle2 {
    font-size: 40px;
  }

  .MissionIcon p {
    font-size: 2.3vh;
  }

  .contenedorMV2 {
    width: 800px;
    height: 100px;
    margin-bottom: 800px;
    margin-left: 5%;
    margin-right: 5%;
  }
  .contenedorImagenes {
    width: 100%;
    height: 900px;
  }
  .illustrationMV2 {
    top: 0px;
    right: 40%;
  }
  .illustrationMV3 {
    top: 0px;
    left: 65%;
  }
  .illustrationMV4 {
    top: 130px;
    left: 20%;
  }
  .contenedorMV2 {
    position: relative;
  }
  .contenedorMV2 img {
    position: absolute;
  }
  .illustrationMV2 {
    width: 70%;
    box-shadow: 0 0 10px rgba(128, 128, 128, 0.5);
    border-radius: 5px;
    z-index: 1; /* Esta imagen estará en la parte superior */
  }

  .illustrationMV3 {
    width: 350px;
    box-shadow: 0 0 10px rgba(128, 128, 128, 0.5);
    border-radius: 10px;
    z-index: 3; /* Esta imagen estará en el medio */
  }

  .illustrationMV4 {
    width: 600px;
    box-shadow: 0 0 10px rgba(128, 128, 128, 0.5);
    border-radius: 10px;
    z-index: 2; /* Esta imagen estará en la parte inferior */
  }
}

@media (max-width: 900px) {
  .textTitle2 {
    font-size: 30px;
  }
  .contenedorMV2 {
    width: 100%;
    height: auto; /* Cambia la altura a auto para que se ajuste al contenido */
    position: relative; /* Añade posición relativa para que las imágenes se posicionen en relación a este contenedor */
    margin-bottom: 150%; /* Ajusta el margen inferior según sea necesario */
  }

  .contenedorImagenes {
    width: 100%;
    height: auto; /* Cambia la altura a auto para que se ajuste al contenido */
    position: relative; /* Añade posición relativa para que las imágenes se posicionen en relación a este contenedor */
  }

  .illustrationMV2,
  .illustrationMV3,
  .illustrationMV4 {
    position: absolute;
    box-shadow: 0 0 10px rgba(128, 128, 128, 0.5);
    border-radius: 10px;
  }

  .illustrationMV2 {
    top: 0;
    right: 30px;
    width: 84%;
    z-index: 1; /* Esta imagen estará en la parte superior */
  }

  .illustrationMV3 {
    top: 100px;
    left: 165px;
    width: 50%;
    z-index: 2; /* Esta imagen estará en el medio */
  }

  .illustrationMV4 {
    top: 280px;
    left: 20px;
    width: calc(
      100% - 80px
    ); /* Ajusta el ancho para que no se salga del contenedor */
    z-index: 3; /* Esta imagen estará en la parte inferior */
  }

  .MissionIcon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .MissionIcon p {
    font-size: 2.3vh;
  }

  .iconInstagrams {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #90caf9;
    font-size: 14vw;
    transition: transform 0.3s ease-in-out;
  }
  .iconInstagrams2 {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1976d2;
    font-size: 14vw;
    transition: color 0.5s ease-in-out;
  }
}

@keyframes zoom {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

.illustrationMV2:hover,
.illustrationMV3:hover,
.illustrationMV4:hover {
  animation: zoom 0.6s;
}
