.App {
  background-color: #ffff;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}
.navBar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.background-Container {
  background-color: #42a5f5;
}

.separatorD {
  width: 0px;
  height: 0px;
  border-left: 100vw solid transparent;
  border-bottom: 30vw solid #ffff;
  margin-top: -29.9vw;
}

.separatorE {
  width: 0px;
  height: 0px;
  border-right: 120vw solid transparent;
  border-top: 34vw solid #ffff;
}

.container-navBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 3vw;
  padding-bottom: 2vw;
  padding-left: 6%;
  padding-right: 6%;
  width: 100%;
}
.navigatorNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  color: #ffff;
  font-weight: 500;
  font-size: 18px;
}

.navigatorNav a {
  color: white;
  text-decoration: none;
  padding-left: 30px;
  font-weight: 600;
  font-size: 18px;
  transition: transform 0.3s ease;
  margin-right: 5vw;
}

.navigatorNav a:hover {
  color: #90caf9;
  transform: scale(1.2);
  transition: 0.5;
}

.imageHeader {
  width: 200px;
}
.buttonInstagram {
  background-color: #90caf9;
  border-radius: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  transition: transform 0.5s;
}

.buttonInstagram:hover {
  transform: scale(1.2);
  transition: 0.5;
}

.iconInstagram {
  color: #ffff;
  font-size: 24px;
}

.App {
  background-color: #ffff;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}
.navBar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.background-Container {
  background-color: #42a5f5;
}
.separatorD {
  width: 0px;
  height: 0px;
  border-left: 100vw solid transparent;
  border-bottom: 30vw solid #ffff;
  margin-top: -29.9vw;
}

.separatorE {
  width: 0px;
  height: 0px;
  border-right: 120vw solid transparent;
  border-top: 34vw solid #ffff;
}

.container-navBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 3vw;
  padding-bottom: 2vw;
  padding-left: 6%;
  padding-right: 6%;
  width: 100%;
}
.navigatorNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  color: #ffff;
  font-weight: 500;
  font-size: 18px;
}

.navigatorNav a {
  color: white;
  text-decoration: none;
  padding-left: 30px;
  font-weight: 600;
  font-size: 18px;
  transition: transform 0.3s ease;
  margin-right: 5vw;
}

.navigatorNav a:hover {
  color: #90caf9;
  transform: scale(1.2);
  transition: 0.5;
}

.imageHeader {
  width: 200px;
}
.buttonInstagram {
  background-color: #90caf9;
  border-radius: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  transition: transform 0.5s;
}

.buttonInstagram:hover {
  transform: scale(1.2);
  transition: 0.5;
}

.iconInstagram {
  color: #ffff;
  font-size: 24px;
}

.triangleBackground {
  position: relative;
  width: 100%;
  z-index: 0;
  align-items: center;
  justify-content: center;
}
.display {
  display: flex;
  z-index: 2;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin-top: 3vh;
  margin-bottom: 10vh;
}

.headerDistribution {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-left: 8vw;
}

#buttonStar {
  margin-top: -20px;
  color: white; /* Cambia el color del texto a blanco */
  font-size: 20px;
  font-weight: 600;
}

.subtitleD {
  font-size: 1.6em;
  font-weight: 600;
  height: 10vh;
  color: #1976d2;
  margin-top: -1vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5vh;
}

.textSlogan {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #ffff;
  text-align: left;
  font-size: 4.5vw;
  font-weight: 700;
  line-height: 110%;
  margin-bottom: 5vh;
}

.headerDistribution2 {
  flex: 2;
  padding-right: 6vw;
}

.illustrationHeader {
  width: 55vw;
}

.App-header {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  z-index: 2;
}

.App-link {
  color: #61dafb;
}

.element {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.formulario {
  display: flex;

  background-color: #ffff;
  color: #1976d2;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  width: 450px;
  height: 880px;
  margin-top: -400px;
  margin-bottom: 100px;
  padding-left: 50px;
  padding-right: 50px;

  /* Agregamos estas propiedades para centrar los elementos */
  display: flex;
  flex-direction: column;
  position: relative;
}

.formulario h4 {
  color: #1976d2;
  font-size: 25px;
  margin-bottom: 0px;
}

.formulario p {
  color: #1976d2;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

.formulario input {
  width: 100%;
  border-radius: 10px;
  border-color: #1976d2;
  border-style: solid;
  border-width: 1px;
  height: 40px;
  padding-left: 10px;
  color: #42a5f5;
}

#comentario {
  height: 100px;
}

.formulario button {
  background-color: #1976d2;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.5);
  border-style: none;
  width: 100%;
  height: 50px;
  border-radius: 8px;
  color: #ffff;
  position: relative;
  bottom: -30px; /* Mueve el botón hacia abajo */
  font-size: 18px;
  font-weight: 600;
  transition: transform 0.5s;
}

.formulario button:hover {
  transform: scale(1.1);
  transition: 0.5;
  transition: transform 0.5s;
}

.element-left {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
.element-right {
  display: flex;
  justify-content: center;
  flex: 0.6;
  flex-direction: column;
  color: #ffff;
  width: 200px;
  height: 400px;
  margin-top: -300px;
  margin-bottom: 50px;
}

.element-right h4 {
  font-size: 80px;
  margin-bottom: 0px;
}

.iconRubpi-white {
  width: 30vw;
  margin-top: 100px;
}

.selector {
  width: 103%;
  border-radius: 10px;
  border-color: #1976d2;
  border-style: solid;
  border-width: 1px;
  height: 40px;
  padding-left: 10px;
  color: #42a5f5;
}

.footcopy {
  background-color: #90caf9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
}
.footcopy p {
  margin-bottom: 0px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hamburger {
  display: none;
}

@media (max-width: 900px) {
  .navBar {
    flex-direction: row;
  }
  .container-navBar {
    flex-direction: row;
    padding-top: 2vw;
    padding-bottom: 4vw;
  }
  .navigatorNav {
    flex-direction: column;
    align-items: center;
  }
  .navigatorNav a {
    padding-left: 0;
    padding-bottom: 10px;
    margin-right: 0;
  }
  .display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }

  .illustrationHeader {
    width: 100vw;
  }

  .textSlogan {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    color: #ffff;
    text-align: center;
    font-size: 14vw;
    font-weight: 700;
    line-height: 110%;
    margin-bottom: 5vh;
  }
  .headerDistribution {
    padding: 0;
    align-items: center;
    justify-content: center;
  }
  .headerDistribution2 {
    align-items: center;
    justify-content: center;
  }

  #buttonStar {
    color: white; /* Cambia el color del texto a blanco */
    font-size: vw;
    text-align: center;
    font-weight: 400;
    margin-bottom: 40px;
  }
  .subtitleD {
    font-size: 5vw;
    color: #1976d2;
    margin-top: 1vh;
    text-align: center;
    margin-bottom: 0vh;
  }
  .container-navBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }
  .navigatorNav {
    display: none;
  }
  .navigatorNav.active {
    display: block;
  }
  .imageHeader {
    width: 40vw;
  }

  .hamburger {
    display: block;
    font-size: 40px;
    color: #ffff;
    transition: color 0.5s ease, transform 0.5s ease;
  }
  .separatorD {
    width: 0px;
    height: 0px;
    border-left: 100vw solid transparent;
    border-bottom: 65vw solid #ffff;
    margin-top: -64.9vw;
  }
  .menuResponsive {
    background-color: #ffff;
    border-radius: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 80%;
    display: flex;
    flex-direction: column;
  }
  .menuResponsive a {
    text-decoration: none;
    font-size: 20px;
    font-weight: 600;
    color: #1976d2;
    padding-bottom: 5px;
  }

  .element {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 30vh;
  }

  .iconRubpi-white {
    display: none;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .menuResponsive {
    animation-name: fadeIn;
    animation-duration: 0.5s;
  }
  .ModalStyle {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 4;
    width: 100%;
    top: 80px;
  }
}
