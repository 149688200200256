.containerGeneral {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
  margin-bottom: 25vh;
}

.contenedorTexto {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 60vh;
  flex: 1;
}

.contenedorTexto p {
  font-size: 24px;
}

.textTitle {
  font-size: 64px;
  color: #42a5f5;
}

.contenedorImagenes {
  flex: 1.3;
}

.IconRubpi {
  margin-top: 15px;
  width: 25vw;
}

.contenedorImagenes {
  position: relative;
  width: 300px;
  height: 605px;
  /* Oculta las barras de desplazamiento */
}

.contenedorImagenes img {
  position: absolute;
}

.illustrationHeader2 {
  top: 8vw;
  left: 3vw;
  width: 42vw;
}

.illustrationHeader3 {
  width: 25vw;
  top: 400px;
  left: 180px;
  box-shadow: 0 0 10px rgba(128, 128, 128, 0.5);
  border-radius: 10px;
}

.illustrationHeader4 {
  width: 15vw;
  top: 220px;
  right: 110px;
  box-shadow: 0 0 10px rgba(128, 128, 128, 0.5);
  border-radius: 10px;
}
.illustrationHeader5 {
  width: 30vw;
  top: 20px;
  right: 170px;
  box-shadow: 0 0 10px rgba(128, 128, 128, 0.5);
  border-radius: 10px;
}

@keyframes zoom {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

.illustrationHeader3:hover,
.illustrationHeader4:hover,
.illustrationHeader5:hover,
.IconRubpi:hover {
  animation: zoom 0.6s;
}

@media (max-width: 1300px) {
  .containerGeneral {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 70vh;
  }

  .contenedorTexto {
    height: 50vh;
  }
  .contenedorTexto p {
    font-size: 2.3vh;
  }
  .textTitle {
    font-size: 40px;
  }
  .IconRubpi {
    width: 0vw;
  }
  .contenedorImagenes {
    width: 100%;
    height: 800px;
  }
  .illustrationHeader2 {
    top: 6vw;
    left: 2vw;
    width: 95%;
  }
  .illustrationHeader3 {
    width: 50vw;
    top: 300px;
    left: 100px;
  }
  .illustrationHeader4 {
    width: 32vw;
    top: 200px;
    right: 40px;
  }
  .illustrationHeader5 {
    width: 60vw;
    top: 0px;
    right: 130px;
  }
}

@media (max-width: 800px) {
  .containerGeneral {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40vh;
  }
  .contenedorTexto p {
    font-size: 2.3vh;
  }

  .contenedorImagenes {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 800px;
    margin-bottom: 1vh;
  }

  .illustrationHeader2,
  .illustrationHeader3,
  .illustrationHeader4,
  .illustrationHeader5 {
    position: relative; /* Cambia la posición a relativa */
  }

  .illustrationHeader2 {
    width: 95%;
  }

  .illustrationHeader3 {
    width: 50vw;
    top: 140px;
    left: 40px;
    z-index: 3;
  }

  .illustrationHeader4 {
    width: 40vw;
    top: 80px;
    left: 200px;
  }

  .illustrationHeader5 {
    width: 70vw;
    left: 80px;
    top: 0px;
  }
  .illustrationHeader3 {
    border-radius: 5px;
  }

  .illustrationHeader4 {
    border-radius: 5px;
  }
  .illustrationHeader5 {
    border-radius: 5px;
  }
}
